import React from 'react';
import BlogSection from "./blogSection";


const Home = () => {

    return (
        <div id="App">
            <BlogSection/>
        </div>
    )
}

export default Home;